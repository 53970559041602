import VueCurrencyFilter from 'vue-currency-filter'
import Vue from 'vue'

export default function () {
  Vue.use(VueCurrencyFilter, {
    symbol : '$',
    thousandsSeparator: ',',
    fractionCount: 0,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: false,
    avoidEmptyDecimals: undefined,
  })
}
