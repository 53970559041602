import { groupBy, map, orderBy, uniq, values } from 'lodash'
import dayjs from 'dayjs'
import { daysRange } from '@/helpers/daysRange'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import {
  isClientsProject,
  isDevelopmentInternalProject, isDevelopmentProject,
  isInternalProject, isMaintenanceProject,
  isMarketingProject
} from '@/helpers/projectTypes'
import mtApi from '@/agGridV2/helpers/mt-api.helper'

dayjs.extend(weekOfYear)

dayjs.Ls.en.weekStart = 1

export default {
  state: {
    activeUsers: [],
    childActivityTags: [],
    teamTimeLog: [],

    tasksTimeLog: null,
    selectedMonth: null
  },

  mutations: {
    setActiveUsers(state, data) {
      state.activeUsers = data
    },
    setChildActivityTags(state, data) {
      state.childActivityTags = data
    },
    setTeamTimeLog(state, data) {
      state.teamTimeLog = data
    },
    setTasksTimeLog(state, data) {
      state.tasksTimeLog = data
    },
    setSelectedMonth(state, month) {
      state.selectedMonth = month
    }
  },

  actions: {
    async fetchTeamTimeLog({ commit }, { month }) {
      const response = await mtApi.getTeamTimeLog({
        date_from: dayjs(month).startOf('month').toISOString(),
        date_to: dayjs(month).startOf('month').add(1, 'month').toISOString(),
      });

      commit('setActiveUsers', response.active_users)
      commit('setChildActivityTags', response.child_activity_tags)
      commit('setTeamTimeLog', response.user_work_day_logs)
    },

    async fetchTasksTimelog({ commit }, { userId, dateFrom, dateTo }) {
      const response = await mtApi.getTasksTeamLog({
        user_id: userId,
        date_from: dateFrom,
        date_to: dateTo,
      });
      commit('setTasksTimeLog', response)
    }
  },

  getters: {
    getActivityTagNameById: (state) => (id) => {
      let founded = state.childActivityTags.find((item) => item.id === id)

      if (founded) {
        return founded.name
      }
    },

    getTotalDuration(state) {
      return state.teamTimeLog.reduce((acc, cur) => {
        return acc + cur.duration
      }, 0)
    },

    getInternalProjectsTotalDuration(state) {
      return state.teamTimeLog
        .filter((item) => isInternalProject(item.project_type))
        .reduce((acc, cur) => {
          return acc + cur.duration
        }, 0)
    },

    getClientsProjectsTotalDuration(state) {
      return state.teamTimeLog
        .filter((item) => isClientsProject(item.project_type))
        .reduce((acc, cur) => {
          return acc + cur.duration
        }, 0)
    },

    getMktIntTotalDuration(state) {
      return state.teamTimeLog
        .filter((item) => item.project_type === 'SEO-INT')
        .reduce((acc, cur) => {
          return acc + cur.duration
        }, 0)
    },

    getDevIntTotalDuration(state) {
      return state.teamTimeLog
        .filter((item) => isDevelopmentInternalProject(item.project_type))
        .reduce((acc, cur) => {
          return acc + cur.duration
        }, 0)
    },

    getOthIntTotalDuration(state) {
      return state.teamTimeLog
        .filter((item) => item.project_type === 'OTH-INT')
        .reduce((acc, cur) => {
          return acc + cur.duration
        }, 0)
    },

    getMktTotalDuration(state) {
      return state.teamTimeLog
        .filter((item) => isMarketingProject(item.project_type))
        .reduce((acc, cur) => {
          return acc + cur.duration
        }, 0)
    },

    getDevTotalDuration(state) {
      return state.teamTimeLog
        .filter((item) => isDevelopmentProject(item.project_type))
        .reduce((acc, cur) => {
          return acc + cur.duration
        }, 0)
    },

    getMntTotalDuration(state) {
      return state.teamTimeLog
        .filter((item) => isMaintenanceProject(item.project_type))
        .reduce((acc, cur) => {
          return acc + cur.duration
        }, 0)
    },

    getSortTasksTimeLog(state) {
      let groupByAlias = []

      for (let item of state.tasksTimeLog) {
        let founded = groupByAlias.find(el => el.alias === item.project_alias);

        if (founded) {
          founded.duration += item.duration_in_ms
          founded.items.push(item)
        } else {
          groupByAlias.push({
            alias: item.project_alias,
            duration: item.duration_in_ms,
            items: [item],
          })
        }
      }

      groupByAlias = groupByAlias.map((el) => {
        el.items = el.items.sort((a, b) => {
          return b.duration_in_ms - a.duration_in_ms
        })

        return el
      })

      return orderBy(groupByAlias, ['duration'], 'desc')
    },

    getUserFullNameById: (state) => (id) => {
      let founded = state.activeUsers.find((item) => item.id === id)

      if (founded) {
        return founded.full_name
      }
    },

    getGroupedTeamTimeLog(state, getters) {
      let endDate = dayjs(state.selectedMonth.code).endOf('month')

      const range = daysRange(
        endDate,
        endDate.daysInMonth()
      )

      const months = uniq(range.map((date) => dayjs(date).format('YYYY-MM')))

      let activityTagIds = uniq(map(state.teamTimeLog, 'user_activity_tag_id'))

      let usersByActivityTags = groupBy(state.teamTimeLog, 'user_activity_tag_id')

      for (let [key, value] of Object.entries(usersByActivityTags)) {
        let formattedValue = Object.entries(value).map(([index, item]) => {
          return {
            id: item.id,
            duration: item.duration,
            started_at: dayjs(new Date(item.started_at)).format('YYYY-MM-DD'),
            user_activity_tag_id: item.user_activity_tag_id,
            user_id: item.user_id
          }
        })

        usersByActivityTags[key] = groupBy(formattedValue, 'user_id')
      }

      // console.log('usersByActivityTags', usersByActivityTags)

      let result = []

      for (let activityTagId of activityTagIds) {
        let itemsByUser = []

        for (let [userId, item] of Object.entries(usersByActivityTags[activityTagId])) {
          let itemsByMonth = []

          for (let month of months) {
            let rangeByMonth = range.filter((date) => dayjs(date).format('YYYY-MM') === month)
            let monthDuration = 0

            let rangeItems = rangeByMonth.map((date) => {
              let founded = item.filter(
                (el) => el.started_at === date
              )

              let obj = {
                duration: 0,
                day: date,
                month: dayjs(date).format('YYYY-MM'),
                week: dayjs(date).week()
              }

              if (founded.length) {
                for (let element of founded) {
                  const day = dayjs(element.started_at)

                  obj.duration += element.duration
                  obj.day = day.format('YYYY-MM-DD')
                  obj.month = day.format('YYYY-MM')
                  obj.week = day.week()
                }
              }

              monthDuration += obj.duration

              return obj
            })

            rangeItems = orderBy(rangeItems, ['day'], ['ask'])

            itemsByMonth.push({
              month: month,
              monthDuration: monthDuration,
              byWeeks: values(groupBy(rangeItems, 'week'))
            })
          }

          itemsByUser.push({
            userId: userId,
            userFullName: getters.getUserFullNameById(userId),
            byMonths: orderBy(itemsByMonth, ['month'], ['ask'])
          })
        }

        result.push({
          userActivityTagId: activityTagId,
          userActivityTagName: getters.getActivityTagNameById(activityTagId) || 'none',
          byUser: orderBy(itemsByUser, ['userFullName'], ['asc'])
        })
      }

      const sortFunc = (a, b) => {
        var sortingArr = state.childActivityTags.map((item) => {
          return item.name
        })

        return (
          sortingArr.indexOf(a.userActivityTagName) -
          sortingArr.indexOf(b.userActivityTagName)
        )
      }

      result = result.sort(sortFunc)

      result = orderBy(result, [item => { return item.userActivityTagName === 'none' }], ['ask'])

      // console.log('getGroupedTeamTimeLog::::', result);
      return result
    },
  }
}
