export const marketingProject = ['SEO']
export const developmentProject = ['DEV', 'FL']
export const maintenanceProject = ['MNT']
export const developmentInternalProject = ['DEV-INT']
export const marketingInternalProject = ['OTH-INT', 'SEO-INT']

export function isMarketingProject(type) {
  return marketingProject.includes(type)
}

export function isDevelopmentProject(type) {
  return developmentProject.includes(type)
}

export function isMaintenanceProject(type) {
  return maintenanceProject.includes(type)
}

export function isDevelopmentInternalProject(type) {
  return developmentInternalProject.includes(type)
}

export function isMarketingInternalProject(type) {
  return marketingInternalProject.includes(type)
}

export function isInternalProject(type) {
  return (
    marketingInternalProject.includes(type) ||
    developmentInternalProject.includes(type)
  )
}

export function isClientsProject(type) {
  return (
    marketingProject.includes(type) ||
    developmentProject.includes(type) ||
    maintenanceProject.includes(type)
  )
}
