import store from '../../store'
import mtApi from '../../agGridV2/helpers/mt-api.helper'

export default async function(to, from, next) {
  // const response = await api.publicApi.signOut()
  const response = await mtApi.logout();
  // if (response.status === 204) {
    store.commit('setMe', {})
    localStorage.clear();
    next({ name: 'login' })
  // } else {
  //   next(false)
  // }
}
