export const UserRole = Object.freeze({
  ADMIN: 'admin',
  SUPERVISOR: 'supervisor',
  MANAGER: 'manager',
  DOER: 'doer',
  GUEST: 'guest'
})

export const AllowedToChangeUserRole = Object.freeze({
  [UserRole.DOER]: 'doer',
  [UserRole.MANAGER]: 'manager',
  [UserRole.GUEST]: 'guest',
  [UserRole.SUPERVISOR]: 'supervisor'
})

export const Labels = Object.freeze({
  [UserRole.DOER]: 'Doer',
  [UserRole.MANAGER]: 'Manager',
  [UserRole.ADMIN]: 'Admin',
  [UserRole.GUEST]: 'Guest',
  [UserRole.SUPERVISOR]: 'Supervisor'
})
