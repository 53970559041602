<template>
  <div class="popup-wrapper">
    <div class="popup">
      <div class="popup-close" @click="$emit('closePopup')">
        <img src="../assets/icons/close-black.svg" alt="">
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.popup-wrapper {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(122, 122, 122, 0.5);
  z-index: 99;
}
.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 60px 100px;
  border-radius: 6px;
  // min-height: 400px;
  // min-width: 735px;
}
.popup-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
</style>
