import {
  millisecondsToHours,
  hoursToMilliseconds,
  millisecondsToMinutes,
  minutesToMilliseconds
} from 'date-fns'

export default {
  millisecondsToTime(time) {
    const hours = millisecondsToHours(time)
    const subHours = hoursToMilliseconds(hours)
    const minutes = millisecondsToMinutes(time - subHours)
    return `${hours}h ${minutes}m`
  },
  numberToHours(number) {
    const hours = Math.floor(number / 3600)
    const minutes = Math.floor((number / 60) % 60)
    let string = ''
    if (hours > 0) {
      if (minutes > 0) {
        string = `${hours}h ${minutes}m`
      } else {
        string = `${hours}h`
      }
    } else if (minutes > 0 && hours === 0) {
      string = `${minutes}m`
    } else {
      string = `0m`
    }
    return {
      string,
      hours,
      minutes
    }
  },
  hoursToNumber(string = '0m') {
    let hours = 0
    let minutes = 0
    const hoursMatch = string.match(/\d+h/)
    const minutesMatch = string.match(/\d+m/)
    if (hoursMatch !== null) {
      hours = parseInt(hoursMatch[0])
    }
    if (minutesMatch) {
      minutes = parseInt(minutesMatch[0])
      if (minutes >= 60) {
        const addHours = Math.floor(minutes / 60)
        hours += addHours
        minutes = minutes - addHours * 60
      }
    }
    if (hoursMatch || minutesMatch) {
      return minutesToMilliseconds(hours * 60 + minutes)
      // return { full: hours + minutes / 60, hours, minutes }
    } else {
      return null
    }
  }
}
