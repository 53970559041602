import { UserRole } from '@/enums/userRole'
import mtApi from '@/agGridV2/helpers/mt-api.helper'

export default {
  state: {
    me: {}
  },
  mutations: {
    setMe(state, user) {
      state.me = user
    }
  },
  actions: {
    async fetchActiveUser({ commit }) {
      const response = await mtApi.getCurrentUser();
      commit('setMe', response);
    }
  },
  getters: {
    me(state) {
      const me = state.me
      if (me && Object.keys(me).length === 0 && me.constructor === Object) {
        return false
      } else {
        return me
      }
    },
    isAdmin(state) {
      return state.me && state.me.role === UserRole.ADMIN
    },
    isManager(state) {
      return state.me && state.me.role === UserRole.MANAGER || state.me.role === UserRole.SUPERVISOR
    },
    isManagerAndBigger(state) {
      return state.me &&
        (
          state.me.role === UserRole.MANAGER ||
          state.me.role === UserRole.SUPERVISOR ||
          state.me.role === UserRole.ADMIN
        )
    },
    isDoer(state) {
      return state.me && state.me.role === UserRole.DOER
    },
    isDoerAndBigger(state) {
      return state.me &&
        (
          state.me.role === UserRole.DOER ||
          state.me.role === UserRole.MANAGER ||
          state.me.role === UserRole.SUPERVISOR ||
          state.me.role === UserRole.ADMIN
        )
    },
    isEfficiencyOnly(state) {
      return state.me?.settings?.is_efficiency_only ?? false
    },
    isIlya(state) {
      return state.me && (state.me.role === UserRole.ADMIN || state.me.email === 'ilya@comradeweb.com')
    },
    isEkaterina(state) {
      return state.me && (state.me.role === UserRole.ADMIN || state.me.email === 'ekaterina.sch@comradeweb.com')
    },
    isAlexey(state) {
      return state.me && (state.me.role === UserRole.ADMIN || state.me.email === 'alex.g@comradeweb.com')
    }
  }
}
